import { useMemo } from 'react'

import bs58 from 'bs58'

import { isRunningInCypress } from '@/root/src/utils/helpers'
import { getNetworkUrls } from '@/utils/helpers'
import { DemonWalletAdapter } from '@/wallet/adapter'
import { E2EWalletAdapter } from '@/wallet/adapter/e2e'
// import { SolflareWalletAdapter } from '@solana/wallet-adapter-wallets'
import { WalletModalProvider } from '@/wallet/ui'
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react'
// import { E2EWalletAdapter } from 'e2e-react-adapter'
import { Keypair } from '@solana/web3.js'

const Provider = ({ children }: HocProps) => {
  const wallets = useMemo(() => {
    const supportedWallets = []
    if (isRunningInCypress) {
      const e2eWalletPrivKey = Cypress.env('E2E_WALLET_PRIVATE_KEY')
      const adapterConfig = { keypair: Keypair.generate() }
      if (e2eWalletPrivKey) {
        adapterConfig.keypair = Keypair.fromSecretKey(bs58.decode(e2eWalletPrivKey))
      }
      Cypress.env('E2E_WALLET_PRIVATE_KEY', bs58.encode(adapterConfig.keypair.secretKey))
      supportedWallets.push(new E2EWalletAdapter(adapterConfig))
    } else {
      supportedWallets.push(new DemonWalletAdapter())
    }

    return supportedWallets
  }, [])

  const urls = useMemo(() => getNetworkUrls(), [])

  return (
    <ConnectionProvider endpoint={urls.rpc} config={{ commitment: 'confirmed', wsEndpoint: urls.wss }}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}

export default Provider
