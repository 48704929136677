export const MAINNET_RPC_URL = 'https://api-mainnet-beta.renec.foundation:8899'
export const MAINNET_WSS_URL = 'wss://api-mainnet-beta.renec.foundation:8900'
export const TESTNET_RPC_URL = 'https://api-testnet.renec.foundation:8899'
export const TESTNET_WSS_URL = 'wss://api-testnet.renec.foundation:8900'
export const EXPLORER_URL = 'https://explorer.renec.foundation'
export const REMITANO_URL = 'https://remitano.com'
export const SITE_URL =
  process.env.NEXT_PUBLIC_P2P_SITE_URL || 'https://develop.d3epdwmmn1npz9.amplifyapp.com'

export const DISPUTE_AWAITING_DURATION_IN_MIN =
  Number(process.env.NEXT_PUBLIC_DISPUTE_AWAITING_DURATION_IN_MIN) || 1 // 1 min
export const A_HUNDRED_PERCENT = 10000
export const TICK_SPACING = 32
export const SLIPPAGE = 1
export const BOND_RATE = 0.01 // TODO: replace by api call

export const SETTINGS_CACHE_EXPIRES_AFTER_SECONDS = 3600 // 1 hour

export const SWAP_RENEC_GAS_FEE = 0.01 // RENEC
export const TRADE_DATE_FORMAT = 'MMM D, YYYY, HH:mm A'
export const DEMON_WALLET_URL = 'https://demon.renec.foundation/'

export const TRANSACTION_FEE_RATIO = 0.0011
