import Decimal from 'decimal.js'

import { SwapTokenInfo } from '@/hooks/use-tokens'
import type { Address } from '@project-serum/anchor'

import { api } from './'

export interface LiquidityPool {
  id: number
  pool_address: Address
  token_a_name: string
  token_a_symbol: string
  token_a_mint_address: Address
  token_b_name: string
  token_b_symbol: string
  token_b_mint_address: Address
  fee: number
}

export const fetchLiquidityPools = async (): Promise<LiquidityPool[]> => {
  // const { data } = await api.get('/pools')
  // return data as LiquidityPool[]
  return [] as LiquidityPool[]
}

export const submitPoolAddressForReview = async (
  poolAddress: string,
  tokenA: SwapTokenInfo,
  tokenB: SwapTokenInfo,
  feePercentage: Decimal.Value = new Decimal(0.01),
) => {
  const { data } = await api.post('/pools', {
    pool_address: poolAddress,
    token_a_name: tokenA.name,
    token_a_symbol: tokenA.symbol,
    token_a_mint_address: tokenA.address,
    token_b_name: tokenB.name,
    token_b_symbol: tokenB.symbol,
    token_b_mint_address: tokenB.address,
    fee: new Decimal(feePercentage).toNumber(),
  })
  return data
}
