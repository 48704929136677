import { TokenInfo } from '@renec-foundation/rpl-token-registry'

const isMainnet = process.env.NEXT_PUBLIC_IS_MAINNET === 'true'
const REUSD_MINT_ADDRESS = isMainnet ? '4Q89182juiadeFgGw3fupnrwnnDmBhf7e7fHWxnUP3S3' : 'Afy8qEgeJykFziRwiCk6tnBbd3uzxMoEqn2GTNCyGN7P'
const REVND_MINT_ADDRESS = isMainnet ? '2kNzm2v6KR5dpzgavS2nssLV9RxogVP6py2S6doJEfuZ' : 'DSodi59U9ZWRnVgP94VNnKamFybYpsqYj2iKL1jQF7Ag'
const RE_NGN_MINT_ADDRESS = isMainnet ? 'BfSYryW6Q93iUKE4uNsUtAdxQT9uU4GSVg2si3outLk1' : 'CHe7TGhNzxpqiW6pdVJ2H2Mw5t7yHXTNyCfzJ1WFR5Dw'

export const customTokens: TokenInfo[] = [
  { symbol: 'reUSD', name: 'USD', address: REUSD_MINT_ADDRESS, chainId: 1, decimals: 9 },
  { symbol: 'reVND', name: 'VND', address: REVND_MINT_ADDRESS, chainId: 2, decimals: 0 },
  { symbol: 'reNGN', name: 'NGN', address: RE_NGN_MINT_ADDRESS, chainId: 2, decimals: 0 },
]

export const availablePairsOfCurrencies = ['USDVND', 'VNDUSD', 'NGNUSD', 'USDNGN']
export const currencyPriorities = ['USD', 'NGN', 'VND']
