import type {
  FC,
  MouseEventHandler,
} from 'react'
import React from 'react'

import useTrans from '@/hooks/useTrans'
import { WalletReadyState } from '@solana/wallet-adapter-base'
import type { Wallet } from '@solana/wallet-adapter-react'

import { Button } from './Button'
import { WalletIcon } from './WalletIcon'

export interface WalletListItemProps {
    handleClick: MouseEventHandler<HTMLButtonElement>;
    tabIndex?: number;
    wallet: Wallet;
}

export const WalletListItem: FC<WalletListItemProps> = ({ handleClick, tabIndex, wallet }) => {
    const trans = useTrans()
    return (
        <li>
            <Button className="h-[100px]" onClick={handleClick} startIcon={<WalletIcon wallet={wallet} />} tabIndex={tabIndex}>
                {wallet.adapter.name}
                {wallet.readyState === WalletReadyState.Installed && <span>{trans.wallet_ui.detected}</span>}
                {wallet.readyState === WalletReadyState.NotDetected && <span>{trans.wallet_ui.not_detected}</span>}
            </Button>
        </li>
    )
}
