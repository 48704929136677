import {
  useCallback,
  useMemo,
} from 'react'

import {
  useFetchTokenList,
  useLiquidityPools,
} from '@/hooks/use-nemo-apis'
import { useSettings } from '@/hooks/use-settings'
import { isMainnet } from '@/utils/helpers'
import {
  ENV as RenecTokenListEnv,
  TokenInfo,
} from '@renec-foundation/rpl-token-registry'
import { IconName } from '@/utils/static-import-icon'
import FiatVnd from '@/assets/svgs/fiat-vnd.svg'
import FiatUsd from '@/assets/svgs/fiat-usd.svg'

export type SwapTokenInfo = TokenInfo & {
  nickName?: string
}

export type FiatTokenInfo = TokenInfo & {
  fiatCurrency?: string,
  fiatIcon?: string,
}

export type PairInfo = [SwapTokenInfo, SwapTokenInfo]

const defaultTokenInfo: TokenInfo = {
  chainId: RenecTokenListEnv.MainnetBeta,
  address: '',
  name: 'Unknown',
  decimals: 9,
  symbol: 'Unknown',
  logoURI: 'https://icotar.com/initials/Not%20Available.svg',
  tags: ['unknown'],
}

export const REUSD = 'reUSD'
export const REUSD_MINT_ADDRESS = isMainnet ?
  '4Q89182juiadeFgGw3fupnrwnnDmBhf7e7fHWxnUP3S3' :
  'Afy8qEgeJykFziRwiCk6tnBbd3uzxMoEqn2GTNCyGN7P'

export const REVND = 'reVND'
export const REVND_MINT_ADDRESS = isMainnet ?
  '2kNzm2v6KR5dpzgavS2nssLV9RxogVP6py2S6doJEfuZ' :
  'DSodi59U9ZWRnVgP94VNnKamFybYpsqYj2iKL1jQF7Ag'

export const RENEC = 'RENEC'
export const RENEC_MINT_ADDRESS = isMainnet ?
  'So11111111111111111111111111111111111111112' :
  'BDsWJbVkjtxUESArcCSrqDfq7HmQZ1m9Zem11VQmz1hB'

export const FIAT_USD = {
  name: 'USD',
  address: 'xxx',
  symbol: 'USD',
  logoURI: FiatUsd,
}

export const FIAT_VND = {
  name: 'VND',
  address: 'xxx',
  symbol: 'VND',
  logoURI: FiatVnd,
}

export const RE_NGN_MINT_ADDRESS = isMainnet ? 'BfSYryW6Q93iUKE4uNsUtAdxQT9uU4GSVg2si3outLk1' : 'CHe7TGhNzxpqiW6pdVJ2H2Mw5t7yHXTNyCfzJ1WFR5Dw'
export const RE_MYR_MINT_ADDRESS = isMainnet ? '' : '9KnRz1XiM4EnPG7ziiYkhfsZJnv3MeyJ7nLU64JpLqx8'
export const RE_INR_MINT_ADDRESS = isMainnet ? '' : 'A5qAyJAJ96wXUigYvvGViDxHkWVKeHsTVnEzcmc4xC9A'

export const RE_FIAT_FIAT_MAP = {
  [REVND_MINT_ADDRESS]: {
    currency: 'VND',
    icon: IconName.FIAT_VND,
  },
  [REUSD_MINT_ADDRESS]: {
    currency: 'USD',
    icon: IconName.FIAT_USD,
  },
  [RE_NGN_MINT_ADDRESS]: {
    currency: 'NGN',
    icon: IconName.FIAT_USD, // TODO: request designer for this fiat icon
  },
  [RE_MYR_MINT_ADDRESS]: {
    currency: 'MYR',
    icon: IconName.FIAT_USD, // TODO: request designer for this fiat icon
  },
  [RE_INR_MINT_ADDRESS]: {
    currency: 'IDR',
    icon: IconName.FIAT_USD, // TODO: request designer for this fiat icon
  },
}

const FIAT_TOKEN_ADDRESSES_WHITELIST = [ REVND_MINT_ADDRESS ]

export const useTokens = (customTokens?: TokenInfo[]) => {
  const { data: onRegistryTokens, isLoading } = useFetchTokenList(customTokens)
  const { data: fetchedFromApiPoolsData, isLoading: isLoadingLiquidityPools } = useLiquidityPools()
  const { data: settingsData } = useSettings()

  const fromRegistryTokens = useMemo(() => {
    return (onRegistryTokens || []).reduce((map, token) => {
      map.set(token.address, token)
      return map
    }, new Map<string, TokenInfo>())
  }, [onRegistryTokens])

  const tokenListMap = useMemo<Map<string, TokenInfo>>(() => {
    const fromPoolsApiTokens = (fetchedFromApiPoolsData || []).reduce((map, pool) => {
      const tokenMintAddressA = pool.token_a_mint_address.toString()
      const foundOnRegistryTokenA = fromRegistryTokens.get(tokenMintAddressA)
      if (foundOnRegistryTokenA) {
        map.set(tokenMintAddressA, foundOnRegistryTokenA)
      } else {
        map.set(tokenMintAddressA, {
          ...defaultTokenInfo,
          address: tokenMintAddressA,
          name: pool.token_a_name,
          symbol: pool.token_a_symbol,
        })
      }

      const tokenMintAddressB = pool.token_b_mint_address.toString()
      const foundOnRegistryTokenB = fromRegistryTokens.get(tokenMintAddressB)
      if (foundOnRegistryTokenB) {
        map.set(tokenMintAddressB, foundOnRegistryTokenB)
      } else {
        map.set(tokenMintAddressB, {
          ...defaultTokenInfo,
          address: tokenMintAddressB,
          name: pool.token_b_name,
          symbol: pool.token_b_symbol,
        })
      }
      return map
    }, new Map<string, TokenInfo>())

    // return new Map<string, TokenInfo>([...fromRegistryTokens, ...fromPoolsApiTokens])
    return fromPoolsApiTokens
  }, [fetchedFromApiPoolsData, fromRegistryTokens])

  const tokenList = useMemo(() => Array.from(tokenListMap.values()), [tokenListMap])

  const fiatTokensList = useMemo<FiatTokenInfo[]>(() => {
    return Array.from(fromRegistryTokens.values())
      .reduce<FiatTokenInfo[]>((acc, tokenInfo) => {
        if ([...FIAT_TOKEN_ADDRESSES_WHITELIST, ...(settingsData?.off_ramp_fiat_tokens || [])].includes(tokenInfo.address)) {
          const fiatInfo = RE_FIAT_FIAT_MAP[tokenInfo.address as keyof typeof RE_FIAT_FIAT_MAP]
          acc.push({
            ...tokenInfo,
            fiatCurrency: fiatInfo.currency,
            fiatIcon: fiatInfo.icon,
          })
        }
        return acc
      }, [])
  }, [fromRegistryTokens, settingsData])

  const fiatTokensMap = useMemo(() => {
    const map = new Map<string, FiatTokenInfo>()
    fiatTokensList.forEach(fiatTokenInfo => {
      map.set(fiatTokenInfo.address, fiatTokenInfo)
    })
    return map
  }, [fiatTokensList])

  const getTokenInfo = useCallback((mintAddress: string) => {
    let tokenInfo = new Map<string, TokenInfo>([...fromRegistryTokens, ...tokenListMap]).get(mintAddress)
    if (!tokenInfo) {
      tokenInfo = {
        ...defaultTokenInfo,
        address: mintAddress,
      }
    }
    return tokenInfo
  }, [fromRegistryTokens, tokenListMap])

  return useMemo(() => ({
    tokenList,
    tokenListMap,
    fromRegistryTokenList: onRegistryTokens,
    fromRegistryTokenListMap: fromRegistryTokens,
    getTokenInfo,
    renecTokenInfo: getTokenInfo(RENEC_MINT_ADDRESS),
    reUsdTokenInfo: getTokenInfo(REUSD_MINT_ADDRESS),
    reVndTokenInfo: getTokenInfo(REVND_MINT_ADDRESS),
    isLoading: isLoading || isLoadingLiquidityPools,
    fiatTokensList,
    fiatTokensMap,
  }), [
    tokenList,
    tokenListMap,
    getTokenInfo,
    isLoading,
    isLoadingLiquidityPools,
    onRegistryTokens,
    fromRegistryTokens,
    fiatTokensList,
    fiatTokensMap,
  ])
}

export default useTokens
