import axios from 'axios'

import { RENEC_MINT_ADDRESS } from '@/hooks/use-tokens'
import { isMainnet } from '@/utils/helpers'
import { BN } from '@project-serum/anchor'
import {
  ENV as RenecTokenListEnv,
  TokenInfo,
  TokenListProvider,
} from '@renec-foundation/rpl-token-registry'

import { api } from './'
import tokenListFixture from './tokens.fixture.json'

export const notify = async (payload: object) => {
  const { data } = await api.post('/helper/notify-order-updates', payload)
  return data
}

export const fetchRemitanoRate = async () => {
  const { data } = await axios.get('https://price.renec.foundation/api/quotes')
  return {
    VND: new BN(1).divn(data.VND).toNumber(),
    REUSD: data.REUSD as number,
  }
}

export const fetchTokenList = async (customTokens?: TokenInfo[]) => {
  // if (customTokens) return customTokens
  const tokens = await new TokenListProvider().resolve()
  const tokenList = [
    {
      chainId: RenecTokenListEnv.MainnetBeta,
      address: RENEC_MINT_ADDRESS,
      symbol: 'RENEC',
      name: 'RENEC',
      decimals: 9,
      logoURI: 'https://remitano.com/coins/renec.png',
      tags: ['community-token'],
      extensions: {
        discord: 'https://discord.gg/gVCbUqNQrZ',
        twitter: 'https://twitter.com/MemeFi01',
      },
    },
    ...(isMainnet ? [] : (tokenListFixture.tokens as TokenInfo[])),
    ...tokens.filterByChainId(RenecTokenListEnv.MainnetBeta).getList(),
  ]
  return tokenList
}

export const fetchGeoInfo = async () => {
  const { data } = await axios.get('/api/geo')
  return data
}

