import AbstractGrid from '@/assets/svgs/abstract-grid.svg'
import AbstractLine from '@/assets/svgs/abstract-line.svg'
import AppleLogo from '@/assets/svgs/apple-logo.svg'
import ArrowCarouselLeft from '@/assets/svgs/arrow-carousel-left.svg'
import ArrowCarouselRight from '@/assets/svgs/arrow-carousel-right.svg'
import ArrowRightBlue from '@/assets/svgs/arrow-right-blue.svg'
import ArrowRightWhite from '@/assets/svgs/arrow-right-white.svg'
import ArrowRight from '@/assets/svgs/arrow-right.svg'
import BlackInstantwire from '@/assets/svgs/black-instantwire.svg'
import CheckCircleIcon from '@/assets/svgs/check-circle-icon.svg'
import CheckIcon from '@/assets/svgs/check-icon.svg'
import Clock from '@/assets/svgs/clock.svg'
import CloseIcon from '@/assets/svgs/close-icon.svg'
import CopyOutlined from '@/assets/svgs/copy-outlined.svg'
import Copy from '@/assets/svgs/copy.svg'
import Discord from '@/assets/svgs/discord.svg'
import Email from '@/assets/svgs/email.svg'
import ExportOutlined from '@/assets/svgs/export-outlined.svg'
import FacebookLogo from '@/assets/svgs/facebook-logo.svg'
import FacebookWhiteIcon from '@/assets/svgs/facebook-white-icon.svg'
import FBBlack from '@/assets/svgs/fb-black.svg'
import FBWhite from '@/assets/svgs/fb-white.svg'
import FiatCny from '@/assets/svgs/fiat-cyn.svg'
import FiatEur from '@/assets/svgs/fiat-eur.svg'
import FiatGbp from '@/assets/svgs/fiat-gbp.svg'
import FiatJpy from '@/assets/svgs/fiat-jpy.svg'
import FiatKrw from '@/assets/svgs/fiat-krw.svg'
import FiatNgn from '@/assets/svgs/fiat-ngn.svg'
import FiatPhp from '@/assets/svgs/fiat-php.svg'
import FiatUsd from '@/assets/svgs/fiat-usd.svg'
import FiatVnd from '@/assets/svgs/fiat-vnd.svg'
import Flash from '@/assets/svgs/flash.svg'
import GoogleLogo from '@/assets/svgs/google-logo.svg'
import InfoCircle from '@/assets/svgs/info-circle.svg'
import InstantwireBlueIcon from '@/assets/svgs/instantwire-blue-icon.svg'
import InstantwireIcon from '@/assets/svgs/instantwire-icon.svg'
import InstantwireLightningBlue from '@/assets/svgs/instantwire-lightning-blue.svg'
import InstantwireLightning from '@/assets/svgs/instantwire-lightning.svg'
import InstantwireLogo from '@/assets/svgs/instantwire-logo.svg'
import InstantwireText from '@/assets/svgs/instantwire-text.svg'
import KycFilled from '@/assets/svgs/kyc-filled.svg'
import LoginIcon from '@/assets/svgs/login-icon.svg'
import MenuBalancesIcon from '@/assets/svgs/menu-balances-icon.svg'
import MenuIcon from '@/assets/svgs/menu-icon.svg'
import MenuKycIcon from '@/assets/svgs/menu-kyc-icon.svg'
import MenuRecipientsIcon from '@/assets/svgs/menu-recipients-icon.svg'
import MenuReferralIcon from '@/assets/svgs/menu-referral-icon.svg'
import MenuTransactionHistoryIcon from '@/assets/svgs/menu-transaction-history-icon.svg'
import MenuWireRaceIcon from '@/assets/svgs/menu-wire-race-icon.svg'
import MoreContactInfo from '@/assets/svgs/more-contact-info.svg'
import NoTransferFee from '@/assets/svgs/no-transfer-fee.svg'
import NoticeMessage from '@/assets/svgs/notice-message-illustrator.svg'
import OpenLinkWhite from '@/assets/svgs/open-link-white.svg'
import OpenLink from '@/assets/svgs/open-link.svg'
import Open from '@/assets/svgs/open.svg'
import Pencil from '@/assets/svgs/pencil.svg'
import Phone from '@/assets/svgs/phone.svg'
import Reddit from '@/assets/svgs/reddit.svg'
import Refresh from '@/assets/svgs/refresh.svg'
import Renec from '@/assets/svgs/renec.svg'
import ReUsd from '@/assets/svgs/reusd.svg'
import Settings from '@/assets/svgs/settings.svg'
import SocialDiscord from '@/assets/svgs/social-discord.svg'
import SocialFacebook from '@/assets/svgs/social-facebook.svg'
import SocialTelegram from '@/assets/svgs/social-telegram.svg'
import SocialTwitter from '@/assets/svgs/social-twitter.svg'
import StartWhiteIcon from '@/assets/svgs/start-white-icon.svg'
import Swap from '@/assets/svgs/swap.svg'
import Switch from '@/assets/svgs/switch.svg'
import TelegramBlack from '@/assets/svgs/tele-black.svg'
import TeleWhiteIcon from '@/assets/svgs/tele-white-icon.svg'
import TelegramWhite from '@/assets/svgs/tele-white.svg'
import Telegram from '@/assets/svgs/telegram.svg'
import Timer from '@/assets/svgs/timer.svg'
import Twitter from '@/assets/svgs/twitter.svg'
import Vnd from '@/assets/svgs/vnd.svg'
import XBlack from '@/assets/svgs/x-black.svg'
import XWhite from '@/assets/svgs/x-white.svg'
import ZoomInIcon from '@/assets/svgs/zoom-in-icon.svg'
import DeleteIcon from '@/assets/svgs/delete-icon.svg'
import UserIcon from '@/assets/svgs/user-icon.svg'
import DownloadIcon from '@/assets/svgs/download.svg'
import PaymentNotYetMadeIcon from '@/assets/svgs/payment-not-yet-made.svg'
import PaymentVerifiedIcon from '@/assets/svgs/payment-verified-icon.svg'
import PaymentVerifyingIcon from '@/assets/svgs/payment-verifying-icon.svg'
import VenmoIcon from '@/assets/svgs/venmo-icon.svg'
import ZelleIcon from '@/assets/svgs/zelle-icon.svg'
import FinalRace from '@/assets/svgs/final-race.svg'
import Copied from '@/assets/svgs/copied.svg'

export const IconName = {
  RENEC: 'renec',
  REUSD: 'reusd',
  SWITCH: 'switch',
  SETTINGS: 'settings',
  DISCORD: 'discord',
  REDDIT: 'reddit',
  TWITTER: 'twitter',
  TELEGRAM: 'telegram',
  NOTICE_MESSAGE: 'notice-message',
  ARROW_RIGHT: 'arrow-right',
  ARROW_RIGHT_BLUE: 'arrow-right-blue',
  ARROW_RIGHT_WHITE: 'arrow-right-white',
  ARROW_CAROUSEL_LEFT: 'arrow-carousel-left',
  ARROW_CAROUSEL_RIGHT: 'arrow-carousel-right',
  EMAIL: 'email',
  PHONE: 'phone',
  INFO_CIRCLE: 'info-circle',
  MORE_CONTACT_INFO: 'more-contact-info',
  PENCIL: 'pencil',
  VND: 'vnd',
  COPY: 'copy',
  COPY_OUTLINED: 'copy-outlined',
  COPIED: 'copied',
  EXPORT_OUTLINED: 'export-outlined',
  KYC_FILLED: 'kyc-filled',
  FLASH: 'flash',
  INSTANTWIRE_LOGO: 'instantwire',
  INSTANTWIRE_TEXT: 'instantwire-text',
  GOOGLE_LOGO: 'google',
  FACEBOOK_LOGO: 'facebook',
  APPLE_LOGO: 'apple',
  FIAT_VND: 'fiat-vnd',
  FIAT_USD: 'fiat-usd',
  FIAT_CNY: 'fiat-cny',
  FIAT_KRW: 'fiat-krw',
  FIAT_JPY: 'fiat-jpy',
  FIAT_PHP: 'fiat-php',
  FIAT_EUR: 'fiat-eur',
  FIAT_GBP: 'fiat-gbp',
  FIAT_NGN: 'fiat-ngn',
  SOCIAL_DISCORD: 'social-discord',
  SOCIAL_FACEBOOK: 'social-facebook',
  SOCIAL_TWITTER: 'social-twitter',
  SOCIAL_TELEGRAM: 'social-telegram',
  BLACK_INSTANTWIRE: 'black-instantwire',
  FB_BLACK: 'fb-black',
  X_BLACK: 'x-black',
  TELEGRAM_BLACK: 'telegram-black',
  FB_WHITE: 'fb-white',
  X_WHITE: 'x-white',
  TELEGRAM_WHITE: 'telegram-white',
  SWAP: 'swap',
  REFRESH: 'refresh',
  NO_TRANSFER_FEE: 'no-transfer-fee',
  CLOCK: 'clock',
  TIMER: 'timer',
  OPEN: 'open',
  ABSTRACT_LINE: 'abstract-line',
  ABSTRACT_GRID: 'abstract-grid',
  OPEN_LINK: 'open-link',
  OPEN_LINK_WHITE: 'open-link-white',
  INSTANTWIRE_LIGHTNING: 'instantwire-lightning',
  INSTANTWIRE_LIGHTNING_BLUE: 'instantwire-lightning-blue',
  INSTANTWIRE_ICON: 'instantwire-icon',
  INSTANTWIRE_BLUE_ICON: 'instantwire-blue-icon',
  TELE_WHITE_ICON: 'tele-white-icon',
  FACEBOOK_WHITE_ICON: 'facebook-white-icon',
  LOGIN_ICON: 'login-white-icon',
  MENU_ICON: 'menu-icon',
  MENU_KYC_ICON: 'menu-kyc-icon',
  MENU_BALANCES_ICON: 'menu-balances-icon',
  MENU_RECIPIENTS_ICON: 'menu-recipients-icon',
  MENU_TRANSACTION_HISTORY_ICON: 'menu-transaction-history-icon',
  MENU_REFERRAL_ICON: 'menu-referral-icon',
  MENU_WIRE_RACE_ICON: 'menu-wire-race-icon',
  CHECK_ICON: 'check-icon',
  CHECK_CIRCLE_ICON: 'check-circle-icon',
  START_WHITE_ICON: 'start-white-icon',
  CLOSE_ICON: 'close-icon',
  ZOOM_IN_ICON: 'zoom-in-icon',
  DELETE_ICON: 'delete-icon',
  USER_ICON: 'user-icon',
  DOWNLOAD_ICON: 'download-icon',
  PAYMENT_NOT_YET_MADE: 'payment-not-yet-made',
  PAYMENT_VERIFIED_ICON: 'payment-verified-icon',
  PAYMENT_VERIFYING_ICON: 'payment-verifying-icon',
  VENMO_ICON: 'venmo-icon',
  ZELLE_ICON: 'zelle-icon',
  FINAL_RACE: 'final-race',
}

export const staticImportIcon = (iconName: string) => {
  switch (iconName) {
    case IconName.SWITCH:
      return Switch
    case IconName.SETTINGS:
      return Settings
    case IconName.REUSD:
      return ReUsd
    case IconName.DISCORD:
      return Discord
    case IconName.REDDIT:
      return Reddit
    case IconName.TWITTER:
      return Twitter
    case IconName.TELEGRAM:
      return Telegram
    case IconName.NOTICE_MESSAGE:
      return NoticeMessage
    case IconName.ARROW_RIGHT:
      return ArrowRight
    case IconName.ARROW_RIGHT_BLUE:
      return ArrowRightBlue
    case IconName.ARROW_RIGHT_WHITE:
      return ArrowRightWhite
    case IconName.ARROW_CAROUSEL_LEFT:
      return ArrowCarouselLeft
    case IconName.ARROW_CAROUSEL_RIGHT:
      return ArrowCarouselRight
    case IconName.EMAIL:
      return Email
    case IconName.PHONE:
      return Phone
    case IconName.INFO_CIRCLE:
      return InfoCircle
    case IconName.MORE_CONTACT_INFO:
      return MoreContactInfo
    case IconName.PENCIL:
      return Pencil
    case IconName.VND:
      return Vnd
    case IconName.COPY:
      return Copy
    case IconName.COPY_OUTLINED:
      return CopyOutlined
    case IconName.EXPORT_OUTLINED:
      return ExportOutlined
    case IconName.KYC_FILLED:
      return KycFilled
    case IconName.FIAT_VND:
      return FiatVnd
    case IconName.FIAT_USD:
      return FiatUsd
    case IconName.FIAT_CNY:
      return FiatCny
    case IconName.FIAT_KRW:
      return FiatKrw
    case IconName.FIAT_JPY:
      return FiatJpy
    case IconName.FIAT_PHP:
      return FiatPhp
    case IconName.FIAT_EUR:
      return FiatEur
    case IconName.FIAT_GBP:
      return FiatGbp
    case IconName.FIAT_NGN:
      return FiatNgn
    case IconName.FLASH:
      return Flash
    case IconName.INSTANTWIRE_LOGO:
      return InstantwireLogo
    case IconName.INSTANTWIRE_TEXT:
      return InstantwireText
    case IconName.GOOGLE_LOGO:
      return GoogleLogo
    case IconName.FACEBOOK_LOGO:
      return FacebookLogo
    case IconName.APPLE_LOGO:
      return AppleLogo
    case IconName.SOCIAL_DISCORD:
      return SocialDiscord
    case IconName.SOCIAL_FACEBOOK:
      return SocialFacebook
    case IconName.SOCIAL_TWITTER:
      return SocialTwitter
    case IconName.SOCIAL_TELEGRAM:
      return SocialTelegram
    case IconName.BLACK_INSTANTWIRE:
      return BlackInstantwire
    case IconName.FB_BLACK:
      return FBBlack
    case IconName.X_BLACK:
      return XBlack
    case IconName.TELEGRAM_BLACK:
      return TelegramBlack
    case IconName.FB_WHITE:
      return FBWhite
    case IconName.X_WHITE:
      return XWhite
    case IconName.TELEGRAM_WHITE:
      return TelegramWhite
    case IconName.SWAP:
      return Swap
    case IconName.REFRESH:
      return Refresh
    case IconName.NO_TRANSFER_FEE:
      return NoTransferFee
    case IconName.CLOCK:
      return Clock
    case IconName.TIMER:
      return Timer
    case IconName.OPEN:
      return Open
    case IconName.ABSTRACT_LINE:
      return AbstractLine
    case IconName.ABSTRACT_GRID:
      return AbstractGrid
    case IconName.OPEN_LINK:
      return OpenLink
    case IconName.OPEN_LINK_WHITE:
      return OpenLinkWhite
    case IconName.INSTANTWIRE_LIGHTNING:
      return InstantwireLightning
    case IconName.INSTANTWIRE_LIGHTNING_BLUE:
      return InstantwireLightningBlue
    case IconName.INSTANTWIRE_ICON:
      return InstantwireIcon
    case IconName.INSTANTWIRE_BLUE_ICON:
      return InstantwireBlueIcon
    case IconName.TELE_WHITE_ICON:
      return TeleWhiteIcon
    case IconName.FACEBOOK_WHITE_ICON:
      return FacebookWhiteIcon
    case IconName.LOGIN_ICON:
      return LoginIcon
    case IconName.MENU_ICON:
      return MenuIcon
    case IconName.MENU_KYC_ICON:
      return MenuKycIcon
    case IconName.MENU_BALANCES_ICON:
      return MenuBalancesIcon
    case IconName.MENU_RECIPIENTS_ICON:
      return MenuRecipientsIcon
    case IconName.MENU_TRANSACTION_HISTORY_ICON:
      return MenuTransactionHistoryIcon
    case IconName.MENU_REFERRAL_ICON:
      return MenuReferralIcon
    case IconName.MENU_WIRE_RACE_ICON:
      return MenuWireRaceIcon
    case IconName.CHECK_ICON:
      return CheckIcon
    case IconName.CHECK_CIRCLE_ICON:
      return CheckCircleIcon
    case IconName.START_WHITE_ICON:
      return StartWhiteIcon
    case IconName.CLOSE_ICON:
      return CloseIcon
    case IconName.ZOOM_IN_ICON:
      return ZoomInIcon
    case IconName.DELETE_ICON:
      return DeleteIcon
    case IconName.USER_ICON:
      return UserIcon
    case IconName.DOWNLOAD_ICON:
      return DownloadIcon
    case IconName.PAYMENT_NOT_YET_MADE:
      return PaymentNotYetMadeIcon
    case IconName.PAYMENT_VERIFIED_ICON:
      return PaymentVerifiedIcon
    case IconName.PAYMENT_VERIFYING_ICON:
      return PaymentVerifyingIcon
    case IconName.VENMO_ICON:
      return VenmoIcon
    case IconName.ZELLE_ICON:
      return ZelleIcon
    case IconName.FINAL_RACE:
      return FinalRace
    case IconName.COPIED:
      return Copied
    case IconName.RENEC:
    default:
      return Renec
  }
}
