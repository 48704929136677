import { ReactElement, createContext, useEffect, useState } from 'react'
import { setCookie } from 'cookies-next'

export type UserStoreContextValue = {
  referralEmail: string | undefined | null,
  referralCode: string | undefined | null,
}

const initContextState: UserStoreContextValue = {
  referralEmail: undefined,
  referralCode: undefined,
}

export const UserStoreContext = createContext<UserStoreContextValue>(initContextState)

const UserStoreProvider = ({ children }: HocProps): ReactElement => {
  const [userStore, setUserStore] = useState<UserStoreContextValue>(initContextState)

  useEffect(() => {
    if (!userStore.referralEmail) {
      const requestQuery = new URLSearchParams(document.location.search)
      if (requestQuery.get('referral') !== null) {
        setUserStore({
          ...userStore,
          referralEmail: requestQuery.get('referral'),
        })
      }
    }

    if (!userStore.referralCode) {
      const requestQuery = new URLSearchParams(document.location.search)
      const referralCode = requestQuery.get('referral_code')
      if (referralCode !== null) {
        setUserStore({
          ...userStore,
          referralCode,
        })
        setCookie('referral_code', referralCode)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UserStoreContext.Provider value={userStore}>
      {children}
    </UserStoreContext.Provider>
  )
}

export default UserStoreProvider
