import { useQuery } from 'react-query'

import useNemoProgram from '@/hooks/use-nemo-program'
import { fetchTokenList } from '@/utils/apis/helper'
import { fetchLiquidityPools } from '@/utils/apis/liquidity'
import { DEFAULT_PROGRAM_ID } from '@/utils/helpers'
import { TokenInfo } from '@renec-foundation/rpl-token-registry'
import { PublicKey } from '@solana/web3.js'

import { fetchCoreTokensList } from '../utils/apis/tokens'

export const useLiquidityPools = () => useQuery(['fetchLiquidityPools'], fetchLiquidityPools, { refetchOnWindowFocus: false })
export const useFetchTokenList = (customTokens?: TokenInfo[]) => useQuery(
  ['fetchTokenList'],
  async () => await fetchTokenList(customTokens),
  { refetchOnWindowFocus: false })

export const useFetchCoreTokenList = () => useQuery(['fetchCoreTokenList'], fetchCoreTokensList, {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
})

export const useFetchAllOnchainPoolAddresses = () => {
  const { context: nemoProgramContext } = useNemoProgram()

  return useQuery(
    ['fetchAllOnchainPoolAddresses'],
    async () => {
      const poolAccounts = await nemoProgramContext.connection.getProgramAccounts(
        new PublicKey(DEFAULT_PROGRAM_ID),
        {
          filters: [{ dataSize: 654 }],
        },
      )
      const allPoolAddresses = poolAccounts.map((account) => account.pubkey.toBase58())
      return allPoolAddresses
    },
    {
      refetchOnWindowFocus: false,
      // enabled: shouldFetch,
    },
  )
}
