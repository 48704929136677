import { api } from './'
import { CoreToken } from './types'

export const fetchCoreTokensList = async (): Promise<CoreToken[]> => {
  const { data } = await api.get('/tokens?token_type=core')
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const tokens = data.items.map((item: any) => ({ 
    id: item.id,
    chainId: item.chain_id,  
    chainName: item.chain_name,
    ownerAddress: item.owner_address,
    coinAddress: item.coin_address,
    name: item.token_name,
    symbol: item.token_symbol,
    logoURL: item.logo_url,
    status: item.status,
    description: item.description,
    website: item.website,
    volume24h: item.volume_24h, 
  } as CoreToken))

  return tokens
}
