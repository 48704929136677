import type {
  FC,
  MouseEvent,
} from 'react'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'

import useTrans from '@/hooks/useTrans'
import { isDemonWalletDetected } from '@/utils/helpers'
import { DemonWalletName } from '@/wallet/adapter'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import type { WalletName } from '@solana/wallet-adapter-base'
import { useWallet } from '@solana/wallet-adapter-react'

import { useWalletModal } from './useWalletModal'
import { WalletListItem } from './WalletListItem'
import { WalletSVG } from './WalletSVG'

export interface WalletModalProps {
  className?: string;
  container?: string;
}

const openNewWindowWithCallback = (url: string, callback?: () => void) => {
  let width = window.innerWidth * 3 / 4
  if (width < 1028) width = 1028
  const height = window.innerHeight * 4 / 5
  const left = window.screenX + (window.innerWidth - width) / 2
  const top = window.screenY + (window.innerHeight - height) / 2
  window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`)
  // newWindow?.addEventListener('unload', () => {
  //   console.log('new window has been closed.')
  //   callback?.()
  // })
  window.addEventListener('focus', () => {
    // eslint-disable-next-line no-console
    console.log('original window has been focused.')
    callback?.()
  })
}

export const WalletModal: FC<WalletModalProps> = ({ className = '', container = 'body' }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { wallets, select } = useWallet()
  const { setVisible } = useWalletModal()
  const [fadeIn, setFadeIn] = useState(false)
  const [portal, setPortal] = useState<Element | null>(null)
  const [openNotice, setOpenNotice] = React.useState(false)
  const trans = useTrans()

  const handleClickOpenNotice = () => {
    setOpenNotice(true)
  }

  const handleCloseNotice = () => {
    setOpenNotice(false)
  }

  const handleConfirmNotice = useCallback(() => {
    openNewWindowWithCallback('https://chrome.google.com/webstore/detail/demon-wallet/mdjmfdffdcmnoblignmgpommbefadffd', () => {
      // eslint-disable-next-line no-console
      console.log('New window has been closed!')
      if (!isDemonWalletDetected()) {
        window.location.reload()
      }
    })
    handleCloseNotice()
  }, [])

  const hideModal = useCallback(() => {
    setFadeIn(false)
    setTimeout(() => setVisible(false), 150)
  }, [setVisible])

  const handleClose = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      hideModal()
    },
    [hideModal],
  )

  const handleWalletClick = useCallback(
    (event: MouseEvent, walletName: WalletName) => {
      if (walletName === DemonWalletName) {
        if (!isDemonWalletDetected()) {
          handleClickOpenNotice()
          return
        }
      }
      select(walletName)
      handleClose(event)
    },
    [select, handleClose],
  )

  const handleTabKey = useCallback(
    (event: KeyboardEvent) => {
      const node = ref.current
      if (!node) return

      // here we query all focusable elements
      const focusableElements = node.querySelectorAll('button')
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const firstElement = focusableElements[0]!
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const lastElement = focusableElements[focusableElements.length - 1]!

      if (event.shiftKey) {
        // if going backward by pressing tab and firstElement is active, shift focus to last focusable element
        if (document.activeElement === firstElement) {
          lastElement.focus()
          event.preventDefault()
        }
      } else {
        // if going forward by pressing tab and lastElement is active, shift focus to first focusable element
        if (document.activeElement === lastElement) {
          firstElement.focus()
          event.preventDefault()
        }
      }
    },
    [ref],
  )

  const isDemonDetected = useMemo(() => isDemonWalletDetected(), [])

  useLayoutEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        hideModal()
      } else if (event.key === 'Tab') {
        handleTabKey(event)
      }
    }

    // Get original overflow
    const { overflow } = window.getComputedStyle(document.body)
    // Hack to enable fade in animation after mount
    setTimeout(() => setFadeIn(true), 0)
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden'
    // Listen for keydown events
    window.addEventListener('keydown', handleKeyDown, false)

    return () => {
      // Re-enable scrolling when component unmounts
      document.body.style.overflow = overflow
      window.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [hideModal, handleTabKey])

  useLayoutEffect(() => setPortal(document.querySelector(container)), [container])

  return (
    portal &&
    createPortal(
      <div
        aria-labelledby="wallet-adapter-modal-title"
        aria-modal="true"
        className={`wallet-adapter-modal ${fadeIn && 'wallet-adapter-modal-fade-in'} ${className}`}
        ref={ref}
        role="dialog"
      >
        <div className="wallet-adapter-modal-container">
          <div className="wallet-adapter-modal-wrapper">
            <button onClick={handleClose} className="wallet-adapter-modal-button-close">
              <svg width="14" height="14">
                <path d="M14 12.461 8.3 6.772l5.234-5.233L12.006 0 6.772 5.234 1.54 0 0 1.539l5.234 5.233L0 12.006l1.539 1.528L6.772 8.3l5.69 5.7L14 12.461z" />
              </svg>
            </button>
            {wallets.length ? (
              <>
                <div className="px-4 pt-6 md:pt-8">
                  <Typography className="text-xl font-semibold">{trans.wallet_ui.dialog_heading}</Typography>
                </div>
                <ul className="wallet-adapter-modal-list mt-6">
                  {wallets.map((wallet) => (
                    <WalletListItem
                      key={wallet.adapter.name}
                      handleClick={(event) => handleWalletClick(event, wallet.adapter.name)}
                      wallet={wallet}
                    />
                  ))}
                </ul>
                {isDemonDetected && (
                  <div className="p-4">
                    <Typography className="text-xs italic font-normal">
                      {trans.wallet_ui.first_time_connect_notice}
                    </Typography>
                  </div>
                )}
                <Dialog
                  open={openNotice}
                  onClose={handleCloseNotice}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {trans.wallet_ui.install_demon_notice_heading}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {trans.wallet_ui.install_demon_notice_body}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleConfirmNotice} autoFocus>
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : (
              <>
                <h1 className="wallet-adapter-modal-title">
                  You'll need a wallet on Solana to continue
                </h1>
                <div className="wallet-adapter-modal-middle">
                  <WalletSVG />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="wallet-adapter-modal-overlay" onMouseDown={handleClose} />
      </div>,
      portal,
    )
  )
}
