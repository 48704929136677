import { useMemo } from 'react'

import useNemoWallet from '@/hooks/use-nemo-wallet'
import { useSettings } from '@/hooks/use-settings'
import { AnchorProvider } from '@project-serum/anchor'
import {
  buildWhirlpoolClient,
  WhirlpoolContext,
} from '@renec-foundation/nemoswap-sdk'
import { useConnection } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { DEFAULT_PROGRAM_ID } from '@/utils/helpers'

const useNemoProgram = () => {
  const { data: settingsData } = useSettings()
  const { placeholderWallet } = useNemoWallet()
  const { connection } = useConnection()

  return useMemo(() => {
    const provider = new AnchorProvider(connection, placeholderWallet, {})
    const context = WhirlpoolContext.withProvider(
      provider,
      new PublicKey(settingsData?.pool_program_id || DEFAULT_PROGRAM_ID),
    )
    const client = buildWhirlpoolClient(context)

    return {
      provider,
      context,
      client,
    }
  }, [placeholderWallet, connection, settingsData])
}

export default useNemoProgram
