import { ReactElement, createContext, useCallback, useEffect } from 'react'
import { useQuery } from 'react-query'
import { fetchGeoInfo } from '@/utils/apis/helper'
import useLang from '@/hooks/useLang'

interface LanguageContextValues {
  handleChangeLanguage: (lang: string) => void;
  lang: string
}

export const LanguageContext = createContext({} as LanguageContextValues)

function Provider({ children }: HocProps): ReactElement {
  const [lang, setLang] = useLang()
  const { data } = useQuery('fetchGeoData', fetchGeoInfo, { refetchOnWindowFocus: false })

  const handleChangeLanguage = useCallback(
    (value: string) => {
      localStorage.setItem('lang', value)
      setLang(value)
    },
    [setLang],
  )

  useEffect(() => {
    if (!localStorage.getItem('lang') && data?.lang) {
      handleChangeLanguage(data?.lang)
    }
  }, [handleChangeLanguage, data?.lang])

  const contextValues = {
    handleChangeLanguage, lang,
  }

  return (
    <LanguageContext.Provider value={contextValues}>
      {children}
    </LanguageContext.Provider>
  )
}

export default Provider
