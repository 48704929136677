import {
  useCallback,
  useMemo,
} from 'react'

import {
  isDemonWalletDetected,
  isRunningInCypress,
} from '@/utils/helpers'
import { DemonWalletName } from '@/wallet/adapter'
import { useWalletModal } from '@/wallet/ui'
import { WalletName } from '@solana/wallet-adapter-base'
import type { AnchorWallet } from '@solana/wallet-adapter-react'
import {
  useAnchorWallet,
  useWallet,
} from '@solana/wallet-adapter-react'
import type {
  Transaction,
  VersionedTransaction,
} from '@solana/web3.js'
import {
  Keypair,
  type PublicKey,
} from '@solana/web3.js'
import {
  DEMON_WALLET_URL,
} from '@/constants/index'

export const newAnchorWallet = (publicKey?: PublicKey): AnchorWallet => ({
  publicKey: publicKey || Keypair.generate().publicKey,
  signTransaction: (tx: Transaction | VersionedTransaction) => new Promise(() => tx),
  signAllTransactions: (txs: (Transaction | VersionedTransaction)[]) => new Promise(() => txs),
})

const useNemoWallet = () => {
  const anchorWallet = useAnchorWallet()
  const { wallet, connect, select, publicKey } = useWallet()
  const { visible, setVisible } = useWalletModal()

  const placeholderWallet = useMemo(() => newAnchorWallet(Keypair.generate().publicKey), [])

  const connectWallet = useCallback(() => {
    if (isRunningInCypress) {
      select('E2E' as WalletName<'E2E'>)
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      connect().catch(() => {})
    } else {
      if (wallet) {
        if (wallet?.adapter?.url && wallet.adapter.url.includes('solanamobile.com/wallets')) {
          wallet.adapter.url = DEMON_WALLET_URL
        }

        if (wallet.adapter.name === DemonWalletName) {
          if (!isDemonWalletDetected()) {
            setVisible(!visible)
            return
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        connect().catch(() => {})
      } else {
        setVisible(!visible)
      }
    }
  }, [
    connect,
    select,
    setVisible,
    visible,
    wallet,
  ])

  return useMemo(() => ({
    anchorWallet,
    publicKey,
    placeholderWallet,
    connectWallet,
  }), [anchorWallet, publicKey, placeholderWallet, connectWallet])
}

export default useNemoWallet
