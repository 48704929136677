import type {
  FC,
  MouseEvent,
} from 'react'
import React, {
  useCallback,
  useMemo,
} from 'react'

import useTrans from '@/hooks/useTrans'

import type { ButtonProps } from './Button'
import { Button } from './Button'
import { useWalletModal } from './useWalletModal'

export const WalletModalButton: FC<ButtonProps> = ({ children, onClick, ...props }) => {
    const { visible, setVisible } = useWalletModal()
    const trans = useTrans()

    const childrenElement = useMemo(() => {
        if (children) return children
        return trans.common.connect_wallet
    }, [children, trans])

    const handleClick = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            if (onClick) onClick(event)
            if (!event.defaultPrevented) setVisible(!visible)
        },
        [onClick, setVisible, visible],
    )

    return (
        <Button className="wallet-adapter-button-trigger" onClick={handleClick} {...props}>
            {childrenElement}
        </Button>
    )
}
