import {
  EXPLORER_URL,
  MAINNET_RPC_URL,
  MAINNET_WSS_URL,
  TESTNET_RPC_URL,
  TESTNET_WSS_URL,
} from '../constants/index'
import { customTokens } from '../constants/token'
import BankList from '../assets/bank_list.json'
import DefaultBankIcon from '@/assets/bank-card-icon.png'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress: any;
  }
}

export const isMainnet = process.env.NEXT_PUBLIC_IS_MAINNET === 'true'

export const getNetworkUrls = () => isMainnet
  ? { rpc: MAINNET_RPC_URL, wss: MAINNET_WSS_URL }
  : { rpc: TESTNET_RPC_URL, wss: TESTNET_WSS_URL }

export const isRunningInCypress = typeof window !== 'undefined' && window.Cypress !== undefined

export const getTransactionDetailUrl = (txId: string) =>
  `${EXPLORER_URL}/tx/${txId}${isMainnet ? '' : '?cluster=testnet'}`

export const isDemonWalletDetected = () => window.demon?.sol

export const removeIntercomActiveClass = () => {
  document.querySelectorAll('.intercom-mobile-messenger-active').forEach(element => {
    element.classList.remove('intercom-mobile-messenger-active')
  })
}

export const onIntercomWidgetShowed = () => {
  document.body.classList.add('intercom-h-0')
}

export const onIntercomWidgetHidden = () => {
  document.body.classList.remove('intercom-h-0')
}

export const detectElementMutated = (element: any, callback: () => void) => {
  const observer = new MutationObserver(callback)

  observer.observe(element, {
    attributes: true,
    attributeFilter: ['class'],
    childList: false,
    characterData: false,
  })
}

export const DEFAULT_PROGRAM_ID = isMainnet ? '7rh7ZtPzHqdY82RWjHf1Q8NaQiWnyNqkC48vSixcBvad' : '4ERwQLtitCdCvSqjzrrVUTeZNfisLNuo3J8HVrbo6mn6'

export const nextIsRunningCypress = process.env.NEXT_PUBLIC_IS_RUNNING_CYPRESS === 'true'

export const minifyAddress = (address: string, length = 4) => {
  const firstPart = address.slice(0, length)
  const lastPart = address.slice(-length)
  return `${firstPart}...${lastPart}`
}

export const isSuccessHttpCode = (code: number) => {
  return /^(2\d{2})$/.test(code.toString())
}

export const getCurrencyInfo = (currency: string) => {
  return customTokens.find((t) => t.name === currency)
}

export const validateEmail = (email: string) => {
  return /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

export const shortenEmail = (email: string) => {
  const arrEmail = email.split('@')
  if(arrEmail.length < 2) return email

  return `${arrEmail[0].slice(0, Math.max(0, arrEmail[0].length - 3))}***@${arrEmail[1]}`
}

export const shortenBankNumber = (bankNumber: string) => {
  return `**** ${bankNumber.substring(Math.max(0, bankNumber.length - 4))}`
}

export const getBankLogoUrl = (bankName: string | undefined) => {
  const url = Object.entries(BankList).find(([key, val]) => {
    return val.shortName.toLowerCase() === bankName?.toLowerCase() ||
      key.toLowerCase() === bankName?.toLowerCase()
  })?.[1]?.bankLogoUrl

  if (url) return url

  return DefaultBankIcon
}

export const getBankBin = (bankName: string | undefined) => {
  return Object.entries(BankList).find(([key, val]) => {
    return val.shortName.toLowerCase() === bankName?.toLowerCase() ||
      key.toLowerCase() === bankName?.toLowerCase()
  })?.[1]?.bin
}

export const currencyToCountry = (currency: string) => {
  switch (currency) {
    case 'VND':
      return 'VN'
    case 'USD':
      return 'US'
    case 'CNY':
      return 'CN'
    case 'PHP':
      return 'PH'
    case 'KRW':
      return 'KP'
    case 'JPY':
      return 'JP'
    case 'GBP':
      return 'UK'
    case 'NGN':
      return 'NG'
    default:
      return undefined
  }
}

export const generateTransferNote = (txHash?: string) => {
  if (txHash) {
    return txHash.length > 5 ? txHash.slice(-5) : txHash
  }
  return 'Transfer Note'
}

export const showIntercomChatBox = () => {
  if (window?.Intercom) {
    window.Intercom('show')
  }
}
