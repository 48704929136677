import type {
  FC,
  MouseEventHandler,
} from 'react'
import React, {
  useCallback,
  useMemo,
} from 'react'

import useNemoWallet from '@/hooks/use-nemo-wallet'
import useTrans from '@/hooks/useTrans'
import { useWallet } from '@solana/wallet-adapter-react'

import {
  Button,
  type ButtonProps,
} from './Button'
import { WalletIcon } from './WalletIcon'

export const WalletConnectButton: FC<ButtonProps> = ({ children, disabled, onClick, ...props }) => {
  const { wallet, connecting, connected } = useWallet()
  const { connectWallet } = useNemoWallet()
  const trans = useTrans()

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (onClick) onClick(event)
      if (!event.defaultPrevented) connectWallet()
    },
    [onClick, connectWallet],
  )

  const content = useMemo(() => {
    if (children) return children
    if (connecting) return `${trans.common.connecting} ...`
    if (connected) return trans.common.connected
    if (wallet) return trans.common.connect
    return trans.common.connect_wallet
  }, [children, connecting, connected, wallet, trans.common])

  return (
    <Button
      className="wallet-adapter-button-trigger"
      disabled={disabled || !wallet || connecting || connected}
      startIcon={wallet ? <WalletIcon wallet={wallet} className="!w-[20px] !h-[20px] rounded-l-lg rounded-r-lg" /> : undefined}
      onClick={handleClick}
      {...props}
    >
      {content}
    </Button>
  )
}
