import { User } from '../utils/apis/types'
import { isSuccessHttpCode } from '../utils/helpers'
import { useAuthorizeRequest } from './use-authorize-request'

export const useUser = () => {
  return useAuthorizeRequest({
    path: '/instantwire/users/me',
    method: 'GET',
    transformResponse: (raw_data: any, _header: any, status?: number) => {
      const data = JSON.parse(raw_data)

      if (status && isSuccessHttpCode(status)) {
        return {
          id: data.id,
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
          queueOrder: data.queue_order,
          inWhitelist: data.in_whitelist,
          referralCode: data.referral_code,
          referreeCount: data.referree_count,
          successTransactionCount: data.success_transaction_count,
          wirePoints: data.total_wire_points,
          totalRefundRefereeTransaction: data.total_refund_referee_transaction,
          draftTransactionCount: data.draft_transaction_count,
          publicKey: data.public_key,
        } as User
      }
      return data
    },
  })
}

