import { useIntercom } from '@thanhvo102/react-use-intercom'
import { useEffect, useState } from 'react'
import {
  removeIntercomActiveClass,
  onIntercomWidgetShowed,
  onIntercomWidgetHidden,
  detectElementMutated,
} from '@/utils/helpers'
import axios from 'axios'
import { useUser } from '@/root/src/hooks/use-user'

declare global {
  interface Window {
    Intercom: any;
  }
}

function BootIntercom(): null {
  const [hasIntercom, setHasIntercom] = useState(false)
  const { boot, update } = useIntercom()
  const { data: user } = useUser()

  useEffect(() => {
    boot()
  }, [boot])

  const updateIntercomUser = async () => {
    if (user) {
      const { data: userHash } = await axios.get('/api/intercom_user_hash')
      update({
        email: user.email,
        customAttributes: {
          instantwire_user: true,
          no_draft_transaction: user.draftTransactionCount === 0,
          no_referrees: user.referreeCount === 0,
        },
        userHash,
      })
    }
  }

  useEffect(() => {
    updateIntercomUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (window.Intercom && !hasIntercom) {
      setHasIntercom(true)

      window.Intercom('onHide', onIntercomWidgetHidden)
      window.Intercom('onShow', onIntercomWidgetShowed)
    }

    detectElementMutated(document.querySelector('html'), removeIntercomActiveClass)
  }, [hasIntercom])

  return null
}

export default BootIntercom
