import type { FC } from 'react'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import useTrans from '@/hooks/useTrans'
import { useWallet } from '@solana/wallet-adapter-react'

import { minifyAddress } from '@/utils/helpers'
import {
  Button,
  type ButtonProps,
} from './Button'
// import { useWalletModal } from './useWalletModal'
import { WalletConnectButton } from './WalletConnectButton'
import { WalletIcon } from './WalletIcon'
import { WalletModalButton } from './WalletModalButton'

export const WalletMultiButton: FC<ButtonProps> = ({ children, ...props }) => {
  const { publicKey, wallet, disconnect } = useWallet()
  // const { setVisible } = useWalletModal()
  const [copied, setCopied] = useState(false)
  const [active, setActive] = useState(false)
  const ref = useRef<HTMLUListElement>(null)
  const trans = useTrans()

  const base58 = useMemo(() => publicKey?.toBase58(), [publicKey])
  const content = useMemo(() => {
    if (children) return children
    if (!wallet || !base58) return null
    return minifyAddress(base58)
  }, [children, wallet, base58])

  const copyAddress = useCallback(async () => {
    if (base58) {
      await navigator.clipboard.writeText(base58)
      setCopied(true)
      setTimeout(() => setCopied(false), 400)
    }
  }, [base58])

  const openDropdown = useCallback(() => {
    setActive(true)
  }, [])

  const closeDropdown = useCallback(() => {
    setActive(false)
  }, [])

  // const openModal = useCallback(() => {
  //   setVisible(true)
  //   closeDropdown()
  // }, [setVisible, closeDropdown])

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const node = ref.current

      // Do nothing if clicking dropdown or its descendants
      if (!node || node.contains(event.target as Node)) return

      closeDropdown()
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, closeDropdown])

  if (!wallet) return <WalletModalButton {...props}>{children}</WalletModalButton>
  if (!base58) return <WalletConnectButton {...props}>{children}</WalletConnectButton>

  return (
    <div className="wallet-adapter-dropdown">
      <Button
        aria-expanded={active}
        className="wallet-adapter-button-trigger"
        style={{ pointerEvents: active ? 'none' : 'auto', ...props.style }}
        onClick={openDropdown}
        startIcon={<WalletIcon wallet={wallet} className="!w-[20px] !h-[20px] rounded-l-lg rounded-r-lg" />}
        {...props}
      >
        {content}
      </Button>
      <ul
        aria-label="dropdown-list"
        className={`wallet-adapter-dropdown-list ${
          active && 'wallet-adapter-dropdown-list-active'
        }`}
        ref={ref}
        role="menu"
      >
        <li onClick={copyAddress} className="wallet-adapter-dropdown-list-item" role="menuitem">
          {copied ? trans.common.copied : trans.common.copy_address}
        </li>
        {/* {wallets.length > 1 && (
          <li onClick={openModal} className="wallet-adapter-dropdown-list-item" role="menuitem">
            {trans.common.change_wallet}
          </li>
        )} */}
        <li onClick={disconnect} className="wallet-adapter-dropdown-list-item" role="menuitem">
          {trans.common.disconnect}
        </li>
      </ul>
    </div>
  )
}
