import axios, { AxiosError } from 'axios'
import { getSession } from 'next-auth/react'

const baseURL =
  process.env.NODE_ENV !== 'production'
    ? process.env.NEXT_PUBLIC_SITE_URL
    : process.env.NEXT_PUBLIC_BACKEND_URL

const TOKEN_EXPIRED_RETRY_ATTEMPTS = 1

export const api = axios.create({
  baseURL: `${baseURL}/api/v1`,
  headers: {
    'Content-type': 'application/json',
  },
})

const multipartApi = axios.create({
  baseURL: `${baseURL}/api/v1`,
  headers: {
    'Content-type': 'multipart/form-data',
  },
})

interface IAuthorizeRequestOptions {
  path: string
  params?: Record<string, any>
  method: string
  multipart?: boolean
  transformResponse?: (data: any, headers: any, status?: number) => any
  tokenExpireRetryCount?: number
  allowAnonymous?: boolean
}

const fetchAccessToken = async () => {
  const session = await getSession()

  return session?.accessToken
}

export const authorizeRequest = async (
  options: IAuthorizeRequestOptions,
  accessToken?: string,
  updateSession?: () => void,
): Promise<any> => {
  const { method, path, params, multipart, transformResponse, allowAnonymous } = options

  const filteredParams =
    params &&
    Object.keys(params).reduce((presentParams, key) => {
      if (params[key]) {
        return { ...presentParams, [key]: params[key] }
      }
      return presentParams
    }, {})

  const apiClient = multipart ? multipartApi : api

  try {
    const newAccessToken = accessToken || (await fetchAccessToken())
    if (!newAccessToken && !allowAnonymous) return
    const { data } = await apiClient(path, {
      method,
      headers: {
        'Access-Token': newAccessToken,
      },
      transformResponse,
      params: multipart ? null : filteredParams,
      data: multipart ? filteredParams : null,
    })
    return data
  } catch (e) {
    const error = e as AxiosError<{ error: string }>
    // TODO: refetch access_token and retry when call api fail with 401 code
    const error_msg = error?.response?.data?.error || String(e)
    if (error_msg === 'Token is expired') {
      // set a max num of attempts to prevent infinite loop
      const retryCount = options.tokenExpireRetryCount || 0
      if (retryCount < TOKEN_EXPIRED_RETRY_ATTEMPTS) {
        // trigger session callback in [src/pages/api/auth/[...nextauth].js] and refresh access_token
        if (updateSession) updateSession?.() // if using with useSession hook

        // trigger api again with new accessToken
        return await authorizeRequest(
          { ...options, tokenExpireRetryCount: retryCount + 1 },
          await fetchAccessToken(),
        )
      }
    }

    throw new Error(error_msg)
  }
}
